.button-slot-timing {
  height: 80%;
  justify-content: center;
  margin-top: 37px;
}

.slotTiming-box-group {
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.slotTiming-box {
  display: flex;
  align-items: center;
  margin: 10px 15px 2px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.slotTiming-box-button {
  margin-left: 7px;
}

.form-slot {
  width: 65%;
  margin: 0px auto;
}

.form-group-slot {
  text-align: left;
}

.form-label-slot {
  font-size: 19px;
}

.session-list-item {
  font-size: 13px;
}
