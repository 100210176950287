@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mt-7 {
  margin-top: 7rem !important;
}

.page-heading {
  color: #4b5e7e;
  font-weight: 800;
  font-size: 2.75rem;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 6px 0px;
}

#dropdown-title {
  color: rgb(75, 94, 126);
}

#dropdown-title:hover {
  color: black;
}

#dropdown-item {
  color: rgb(75, 94, 126);
  text-align: center;
}

#dropdown-item:hover {
  color: black;
  background-color: rgb(255, 255, 255);
}

#dropdown-item.active {
  color: black;
  background-color: rgb(255, 255, 255);
}

.navbar-item {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 30px;
  display: inline-block !important;
  white-space: nowrap;
  align-self: center;
}

#navbar-item-signin {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  white-space: nowrap;
  word-spacing: 0px;
  text-decoration: none;
}

#navbar-item-signin:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-btn__book {
  align-self: center;
  height: 35px;
  background-color: rgb(250, 179, 0) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px 0px;
  border-radius: 6px !important;
}

.navbar-btn__book:hover {
  background-color: rgb(75, 94, 126) !important;
}

.navbar-btn__book-text {
  font-size: 18px;
  text-align: start;
  white-space: nowrap;
  background-origin: padding-box;
  color: white;
}

.img-signin {
  align-self: center;
}

.passwordResetLink {
  text-align: start;
}

.loginButton {
  background: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  justify-content: var(--label-align);
  min-width: 100%;
}
.loginButton:hover {
  background-color: rgb(75, 94, 126, 0.9);
  border-color: rgb(75, 94, 126, 0.9);
}

.authLineBreak {
  background-color: black;
  color: rgb(0, 0, 0);
  font-weight: 900;
  width: 100%;
  height: 1px;
}

.registerButton {
  background-color: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  justify-content: var(--label-align);
  min-width: 100%;
}
.generatePasswordButton {
  background-color: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  justify-content: var(--label-align);
  min-width: 100%;
}

.loginModal {
  width: 100%;
  height: 100%;
}

/* 
For reference

.modal .modal-dialog {
  width: 100% !important;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal .modal-content {
  width: 100% !important;
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal .modal-body {
  overflow-y: auto;
}
.modal-header {
  border-bottom: none;
}

button.close {
  height: min-content !important;
  width: min-content !important;
  margin-right: 3% !important;
} */

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

#live-online-tutor {
  color: rgb(75, 94, 126);
  font-weight: 400;
  line-height: normal;
}

#math-coding {
  color: rgb(252, 181, 0);
  font-weight: 400;
  line-height: normal;
}

.landing-intro-box span {
  color: rgb(75, 94, 126);
  font-weight: 700;
  line-height: normal;
}

.content-center {
  color: rgb(75, 94, 126) !important;
  font-weight: 200;
}

.btn__book-class {
  color: white !important;
  background-color: rgb(250, 179, 0) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px 0px;
}

.btn__book-class:hover {
  background-color: rgb(75, 94, 126) !important;
}

.btn.btn-square {
  border-radius: 8px;
}

.container-nyu_info {
  background-color: rgb(75, 94, 126);
  color: white;
  font-size: 25px;
  font-weight: 400;
  border-radius: 20px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px 0px;
}

.container-info {
  background-color: rgb(237, 237, 237);
  padding: 2px !important;
  margin: 2px !important;
}

.img-info h4 {
  color: rgb(75, 94, 126);
  font-weight: 700;
}

.img-item-info {
  min-width: 90px;
}

.container-book_class-end {
  background-color: rgb(250, 179, 0);
  color: white !important;
  padding: 25px;
  border-radius: 20px;
}

.btn__book-class-end {
  color: rgb(250, 179, 0) !important;
  font-size: 12px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px 0px;
  border-radius: 160px !important;
  text-align: center !important;
}

.btn__book-class-end:hover {
  color: black !important;
  background-color: rgb(196, 196, 196) !important;
}

.img-kid-landing {
  max-height: 225px;
  min-width: 340px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .landing-intro-box h3 {
    font-size: 30px;
  }
  .container-nyu_info h3 {
    font-size: 18px;
  }
  .landing-intro-box h4 {
    font-size: 27px;
  }
  .landing-intro-box span {
    font-size: 27px;
  }
  .img-info h4 {
    font-size: 17px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .landing-intro-box h3 {
    font-size: 36px;
  }
  .container-nyu_info h3 {
    font-size: 20px;
  }
  .landing-intro-box h4 {
    font-size: 31px;
  }
  .landing-intro-box span {
    font-size: 20px;
  }
  .img-info h4 {
    font-size: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .landing-intro-box h3 {
    font-size: 34px;
  }
  .container-nyu_info h3 {
    font-size: 20px;
  }
  .landing-intro-box h4 {
    font-size: 29px;
  }
  .landing-intro-box span {
    font-size: 20px;
  }
  .img-info h4 {
    font-size: 22px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .landing-intro-box h3 {
    font-size: 44px;
  }
  .container-nyu_info h3 {
    font-size: 24px;
  }
  .landing-intro-box h4 {
    font-size: 35px;
  }
  .landing-intro-box span {
    font-size: 19px;
  }
  .img-info h4 {
    font-size: 24px;
  }
}



.form-service {
  width: 50%;
  margin: 0 auto;
}

.form-group-service {
  text-align: left;
}

.form-label-service {
  font-size: 19px;
}

.head-questions-list {
  text-align: center;
}

#button-answer {
  height: 34%;
  justify-content: center;
  margin-top: 37px;
  margin-left: 3px;
}

.answers-box-group {
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.payment-type-label {
  white-space: nowrap;
}

.answer-form {
  width: 80%;
  justify-content: right;
}

.answers-box {
  display: flex;
  align-items: center;
  margin: 10px 15px 2px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.answers-box-button {
  margin-left: 7px;
}

#answer-edit-button-group {
  float: left;
  display: inline-flex;
  padding-top: 36px;
}

.head-questions-list {
  padding-top: 450px;
}

.question-list-item {
  font-size: 19px;
}



.button-slot-timing {
  height: 80%;
  justify-content: center;
  margin-top: 37px;
}

.slotTiming-box-group {
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.slotTiming-box {
  display: flex;
  align-items: center;
  margin: 10px 15px 2px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.slotTiming-box-button {
  margin-left: 7px;
}

.form-slot {
  width: 65%;
  margin: 0px auto;
}

.form-group-slot {
  text-align: left;
}

.form-label-slot {
  font-size: 19px;
}

.session-list-item {
  font-size: 13px;
}

.form-session {
  width: 45%;
  margin: 0 auto;
}

.form-group-slot {
  text-align: left;
}

.form-label-slot {
  font-size: 19px;
}

#sessions-list {
  overflow-y: auto;
}

.not-found{
    min-height: 100vh;
    display: flex;
    align-items: center;
    margin: auto;
}
.not-found-heading{
    font-size: 4rem;
    font-weight: 600;
    color: #0e2152;
}
.not-found-msg{
    font-size: 1.3rem;
}
.not-found-btn{
    background-color: #0e2152 !important;
    border: none !important;
    margin: 2rem 0.5rem;
    padding: 0.5rem 2rem !important;
    width: 100px !important;
}
