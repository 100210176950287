.form-session {
  width: 45%;
  margin: 0 auto;
}

.form-group-slot {
  text-align: left;
}

.form-label-slot {
  font-size: 19px;
}

#sessions-list {
  overflow-y: auto;
}
