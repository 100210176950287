.not-found{
    min-height: 100vh;
    display: flex;
    align-items: center;
    margin: auto;
}
.not-found-heading{
    font-size: 4rem;
    font-weight: 600;
    color: #0e2152;
}
.not-found-msg{
    font-size: 1.3rem;
}
.not-found-btn{
    background-color: #0e2152 !important;
    border: none !important;
    margin: 2rem 0.5rem;
    padding: 0.5rem 2rem !important;
    width: 100px !important;
}