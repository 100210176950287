.form-service {
  width: 50%;
  margin: 0 auto;
}

.form-group-service {
  text-align: left;
}

.form-label-service {
  font-size: 19px;
}

.head-questions-list {
  text-align: center;
}

#button-answer {
  height: 34%;
  justify-content: center;
  margin-top: 37px;
  margin-left: 3px;
}

.answers-box-group {
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.payment-type-label {
  white-space: nowrap;
}

.answer-form {
  width: 80%;
  justify-content: right;
}

.answers-box {
  display: flex;
  align-items: center;
  margin: 10px 15px 2px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.answers-box-button {
  margin-left: 7px;
}

#answer-edit-button-group {
  float: left;
  display: inline-flex;
  padding-top: 36px;
}

.head-questions-list {
  padding-top: 450px;
}

.question-list-item {
  font-size: 19px;
}
